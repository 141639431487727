import React from 'react'
import { hooks, I18n } from '@front/volcanion'

import StatusUtils from '@onix/utils/status'

const withContainer = Component => props => {
  const [{ contract }] = hooks.useFormState()
  const [status, deferred_billing] = hooks.useFieldValues(['status', 'deferred_billing'])
  const { DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER } = hooks.useRelayFeatures(['DRIVER_CAN_CALL_CLIENT', 'CLIENT_CAN_CALL_DRIVER'])
  const [companycontract] = hooks.useModel('companycontract', [contract], { single: true })
  const deferred_billing_options = !companycontract?.auth_account
    ? _.uniqBy([{ label: I18n.t('action.forbidden'), value: 'forbidden' }, { label: I18n.t(`action.${deferred_billing}`), value: deferred_billing }], 'value')
    : [
      { label: I18n.t('action.forbidden'), value: 'forbidden' },
      { label: I18n.t('action.allowed'), value: 'allowed' },
      { label: I18n.t('action.mandatory'), value: 'mandatory' }
    ]

  const mergedProps = {
    color: StatusUtils.getColor(status),
    DRIVER_CAN_CALL_CLIENT,
    CLIENT_CAN_CALL_DRIVER,
    deferred_billing_options,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
