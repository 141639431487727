import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['reservationinfos.reservationinfo', 'info'] })

  const order_reservationinfo = _.head(order?.reservationinfos)
  const display_reservationinfo = !!order_reservationinfo
  const meeting_point_translated = order?.info?.meeting_point_translated

  const mergedProps = {
    display_reservationinfo,
    meeting_point_translated,
    order_reservationinfo,
  }

  return <Component {...mergedProps} {...props} />

}

export default withContainer