class Callbacks {
  static handleInvoiceDownloadHanlder(downloadInvoice, openNotification) {
    return async function handleInvoiceDownload() {
      try {
        await downloadInvoice()
      }
      catch (err) {
        console.warn("Failed to download file", err)
        openNotification('Echec du téléchargement', { variant: 'error' })
      }
    }
  }

  static isDownloadAvailableStatusHandler(isDownloadAvailable, setDisplayDownloadButton) {
    return async function isDownloadAvailableStatus(order_id) {
      try {
        const response = await isDownloadAvailable({ order_id, type: 'invoice' })
        setDisplayDownloadButton(!!response?.status)

      } catch (err) {
        console.error('failed to get download available status', err)
        setDisplayDownloadButton(false)
      }
    }
  }

}

export default Callbacks
