import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid, Typography } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = (props) =>
  <Box display='flex' justifyContent='space-between'>
    <Typography color='primary' fontWeight={600} fontSize={15}>{I18n.t('companyuser.associated')} </Typography>
  </Box>

const ServiceSection = ({ navigate, record_id, company, contract, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id, contract), [record_id, contract])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const handleRowClick = useCallback((rowId, row) => !!_.get(row, 'companyuser.companyuser_id') && window.open(`/admin/user/${_.get(row, 'companyuser.companyuser_id')}`), [])
  return (
    <Box sx={{ p: 2, border: 1, borderColor: 'primary.main', borderRadius: 1, overflow: 'auto' }}>
      <Grid item container rowSpacing={3}>
        <Grid item xs={12}><Title /></Grid>
        <Grid item xs={12}>
          <SearchFrame
            model_name={'companyuserservices'}
            populate={['companyuser.user.auth', 'companyuser.user.info', 'companyservice.contract']}
            allowCreate={false}
            createPlacement={'bottom'}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount={!!record_id}
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                recordToForm,
                formToRecord,
                getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
                getSuccessNotification: useCallback(Callbacks.getSuccessNotificationHandler(), []),
                disableParentSubmitListener: true
              }}
              tableProps={{
                onRowClick: handleRowClick,
                enableToolbar: false,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow company={company}><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions><ButtonGlobalAction /></TableActions>
            </SearchTable>
          </SearchFrame >
        </Grid>
      </Grid>
    </Box >
  )
}

export default React.memo(ServiceSection)
