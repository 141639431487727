import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid } from '@mui/material'
import { TextField } from '@front/squirtle'
import AccountSelector from '@onix/elements/AccountSelector'

const First = ({ selectedCompany: company, afterChange }) =>
  <Grid container item xs justifyContent='center' alignItems='center'>
    <Grid item xs={12}>
      <AccountSelector
        company={company}
        watchers={[company]}
        required
        _label={{ sx: { backgroundColor: 'extraWhite', pl: 1, pr: 1 } }}
        afterChange={afterChange}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label={I18n.t('hostel_passenger')}
        name={'passenger_name'}
        variant='outlined'
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label={I18n.t('gsm.label', { count: 1 })}
        name={'passenger_number'}
        variant='outlined'
        required
      />
    </Grid>
  </Grid>

export default React.memo(First)
