import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { TableRow, TableHeader, SearchFrame, TableRowActions, TableActions, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Typography, Box } from '@mui/material'

import Callbacks from './callbacks'

import Header from './Header'
import Row from './Row'
import Form from './Form'

import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = props =>
  <Box display='flex' justifyContent='space-between'>
    <Typography color='primary' fontSize={15} fontWeight={600}>{I18n.t('schedule.label', { count: 1 })} </Typography>
  </Box>

const Schedules = props => {
  const [record_id] = hooks.useFormState(['record_id'])

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  return <Box sx={{ p: 2, border: 1, borderColor: 'primary.main', borderRadius: 1 }}>
    <Title />
    <SearchFrame
      model_name={'companyserviceschedules'}
      populate={['schedule']}
      allowCreate={false}
    >
      <SearchForm
        submitOnMount={!!record_id}
        disableButton
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        formProps={{
          recordToForm,
          formToRecord,
          submitChangesOnly: false,
          disableParentSubmitListener: true
        }}
        tableProps={{
          enableToolbar: false,
          enablePagination: false,
          enableRowActions: true,
          enableRowSelection: false,
          enableGlobalActions: true,
          enableGlobalActionsPlacement: 'bottom',
          disableCancelRowLabel: true,
          disableSaveRowLabel: true
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
        <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
        <TableActions><ButtonGlobalAction getEmptyForm={getEmptyForm} /></TableActions>
      </SearchTable>
    </SearchFrame >
  </Box >
}

export default React.memo(Schedules)
