import React from 'react'
import { I18n } from '@front/volcanion'

import { Button, Collapse, Box, Grid, Typography } from '@mui/material'
import { MenuSelectorField, AutocompleteField, DateTimeField } from '@front/squirtle'

const SearchForm = ({ requestSubmit, order_status, options, tab, ...props }) =>
  <Collapse in={tab === 3} timeout={1000}>
    <Box sx={{ bgcolor: 'extraWhite', p: 2, boxShadow: 6, m: 1 }}>
      <Grid container spacing={1} alignItems='flex-end' justifyContent={'center'}>
        <Grid container item xs={12} lg={10} spacing={1}>
          <Grid item xs>
            <MenuSelectorField
              label={I18n.t('type.label', { count: 1 })}
              name={'account_type'}
              options={options}
              noneLabel={I18n.t('all')}
            />
          </Grid>
          <Grid item xs>
            <DateTimeField
              label={I18n.t('date.search')}
              name={'requestedAt_start'}
              compact
              renderInputProps={{
                InputLabelProps: { shrink: true }
              }}

            />
          </Grid>
          <Grid item xs>
            <DateTimeField
              label={I18n.t('date.at')}
              name={'requestedAt_end'}
              compact
              renderInputProps={{
                InputLabelProps: { shrink: true }
              }}
            />
          </Grid>
          <Grid item xs>
            <AutocompleteField
              name='status'
              label={I18n.t('order.status.label', { count: 1 })}
              options={order_status}
              multiple
              clearOnEmpty
              renderInputProps={{
                InputLabelProps: { shrink: true }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='contained' onClick={requestSubmit} sx={{ textTransform: 'none' }} >
              <Typography> {I18n.t('action.search')} </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Collapse>



export default React.memo(SearchForm)
