import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [comment] = hooks.useFieldValues(['comment'])

  const mergedProps = {
    comment
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer