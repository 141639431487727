import React from 'react'

import { Typography, Grid, Box } from '@mui/material'

const ReservationInfoComponent = ({ display_reservationinfo, meeting_point_translated, order_reservationinfo }) =>
  <Grid container>
    <Box sx={{ display: !!display_reservationinfo ? 'flex' : 'none', flexDirection: 'column', mr: 1 }}>
      <Typography sx={{ fontSize: 13, mr: 1 }}>{meeting_point_translated}</Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontSize: 13, mr: 1, fontWeight: 600, color: 'text.label' }}>{order_reservationinfo?.reservationinfo?.name_translated} :</Typography>
        <Typography sx={{ fontSize: 13, mr: 1, fontWeight: 200, color: 'text.input' }}>{order_reservationinfo?.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontSize: 13, mr: 1, fontWeight: 600, color: 'text.label' }}>{order_reservationinfo?.reservationinfo?.origin_translated} :</Typography>
        <Typography sx={{ fontSize: 13, mr: 1, fontWeight: 200, color: 'text.input' }}>{order_reservationinfo?.origin}</Typography>
      </Box>
    </Box>
  </Grid>


export default React.memo(ReservationInfoComponent)
