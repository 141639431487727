import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [driver_id, vehicle_id] = hooks.useFieldValues(['driver', 'vehicle'])

  const [formatted_driver, { isLoading: isLoadingDriver }] = hooks.useModel('user', [driver_id], {
    populate: ['info'],
    single: true,
    mutator: elem => _.join([_.get(elem, 'info.user_client_id'), _.get(elem, 'info.first_name')], ' ')
  })
  const [vehicle, { isLoading: isLoadingVehicle }] = hooks.useModel('vehicle', [vehicle_id], {
    populate: ['info.model.brand'],
    single: true,
    mutator: elem => ({
      formatted_vehicle: _.join([_.get(elem, 'model.name'), _.get(elem, 'info.model.brand.name'), _.get(elem, 'info.color')], ' '),
      ...elem
    })
  })

  const mergedProps = {
    formatted_driver,
    formatted_vehicle: _.get(vehicle, 'formatted_vehicle'),
    plate_vehicle: _.get(vehicle, 'info.plaque')
  }

  return (
    <Loader isLoading={!!isLoadingDriver || !!isLoadingVehicle} >
      <Component {...mergedProps} {...props} />
    </Loader >
  )
}
export default withContainer