import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const mergedProps = {
    requestSubmit
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
