import React from 'react'

import { Box } from '@mui/material'

import FirstColumn from './Column/First'
import SecondColumn from './Column/Second'
import ThirdColumn from './Column/Third'

const SecondSection = ({ load_type, isB2B }) =>
  <Box sx={{ flexDirection: 'row', display: 'flex' }}>
    <FirstColumn isB2B={isB2B} />
    <SecondColumn isB2B={isB2B} />
    <ThirdColumn isB2B={isB2B} />
  </Box>

export default React.memo(SecondSection)
