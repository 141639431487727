import React from 'react'
import { I18n } from '@front/volcanion'

import { RatingComponent, DialogContent, FormDialog } from '@front/squirtle'
import { Box, Grid, Typography } from '@mui/material'

import RatingForm from '../../Popup/Rating'

const RatingSection = ({ onReadOnlyChange, openRatingPopup, submitRatingPopup, disabled, rating, comment, ...props }) => <>
  <FormDialog
    name={'rating_form'}
    formProps={{ onSubmit: submitRatingPopup }}
  >
    <DialogContent>
      <RatingForm />
    </DialogContent>
  </FormDialog>

  <Grid item xs={12} alignItems='center'>
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ fontSize: 15, fontWeight: 600, color: 'text.label' }}> {I18n.t('rating.rate')}: </Typography>
      <RatingComponent
        disabled={disabled}
        name='rating'
        value={rating}
        onChange={openRatingPopup}
      />
      <Box sx={{ display: !!disabled ? 'flex' : 'none', alignItems: 'center' }}>
        <Typography sx={{ ml: 1, fontSize: 15, fontWeight: 600, color: 'text.label' }}> {I18n.t('comment.label', { count: 1 })}: </Typography>
        <Typography sx={{ ml: 1, fontSize: 13, fontWeight: 200, color: 'text.label' }}> {comment}</Typography>
      </Box>
    </Box>
  </Grid >
</>

export default React.memo(RatingSection)
