import { I18n } from '@front/volcanion'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler(record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        companyservice: record_id,
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        c_user: _.get(record, 'companyuser.companyuser_id'),
        ...record,
      }
    }
  }

  static formToRecordHandler(record_id) {
    return function formToRecord(values, extra, meta) {
      return {
        companyuser: _.has(values, 'c_user') ? _.get(values, 'c_user') : undefined,
        companyservice: record_id || extra?.companyservice,
      }
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta, state) {
      return [I18n.t('companyuser.associated_failed'), { variant: 'error' }]
    }
  }
  static getSuccessNotificationHandler() {
    return function getSuccessNotification(err, values, extra, meta, state) {
      const { omit_success_notification } = extra
      return !!omit_success_notification ? [] : [I18n.t('companyuser.associated_success'), { variant: 'success' }]
    }
  }
}

export default Callbacks
