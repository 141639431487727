import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Icon, InputAdornment, Typography, Button } from '@mui/material'
import { TextComponent } from '@front/squirtle'

const CustomTextComponent = ({ value, label, hidden = false, iconName, ...props }) =>
  <Box>
    <Box display={'flex'} alignItems={'center'}>
      <Icon color={'primary'} baseClassName={'material-icons-outlined'}>
        {iconName}
      </Icon>
      <TextComponent
        value={value}
        InputProps={{
          startAdornment: <InputAdornment disableTypography sx={{ fontSize: 13, fontWeight: 600, color: 'text.label', mr: 1 }}>
            {label}
          </InputAdornment>,
          inputProps: { style: { fontSize: 13, marginTop: 4 } }
        }}
        disabled
      />
    </Box>
  </Box>

const ThirdColumn = ({ customer_ref, payment_type, discount, total, handleInvoiceDownload, displayDownloadButton, ...props }) =>
  <Box>
    <CustomTextComponent value={customer_ref} label={I18n.t('payment.ref')} />
    <CustomTextComponent value={payment_type} label={I18n.t('payment.label', { count: 1 })} iconName={'credit_card'} />
    <CustomTextComponent value={total} label={I18n.t('total.label', { count: 1 })} />
    {!!displayDownloadButton &&
      <Button
        sx={{ bgcolor: 'primary.main', color: 'white', "&:hover": { color: "primary.main", bgcolor: 'white' } }}
        size='small'
        variant='contained'
        onClick={handleInvoiceDownload}
      >
        <Typography sx={{ ml: 1, fontSize: 'inherit' }}> {I18n.t('billing.download')} </Typography>
      </Button>
    }
  </Box>

export default React.memo(ThirdColumn)
