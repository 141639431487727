import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const companyservice_schedule_id = _.get(row, 'companyservice_schedule_id')
  const [deleteRecord] = hooks.useModelFunction('companyserviceschedules', 'delete')
  const onDelete = useCallback(() => deleteRecord({ companyservice_schedule_id })
    , [companyservice_schedule_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
