import React, { useCallback } from 'react'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ form_name = 'order_quick_form', ...props }) => {
  const formProps = {
    name: form_name,
    model_name: 'order',
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    redirectOnGenerate: false,
    includeReadOnly: true
  }
  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
