import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [order] = hooks.useModel('order', [record_id], { single: true })
  const isReady = hooks.useFormStatus('isReady')
  const mergedProps = {
    status: _.get(order, 'status'),
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
