import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const [rating, setRating] = hooks.useFormState('rating')
  const [ratingComment] = hooks.useFormState('ratingComment')
  const [client_id] = hooks.useFormState('client_id')
  const [record_id] = hooks.useFormState('record_id')
  const [generateRating] = hooks.useModelFunction('rating', 'generate')
  const { openNotification } = hooks.useNotification()

  const submitRatingPopup = useCallback(PopupCallbacks.submitRatingPopupHandler(generateRating, record_id, openNotification, setRating), [generateRating, record_id, openNotification, setRating])
  const openRatingPopup = useCallback(PopupCallbacks.openRatingPopupHandler(openDialog, client_id), [openDialog, client_id])

  const mergedProps = {
    disabled: !!rating,
    openRatingPopup,
    submitRatingPopup,
    rating,
    comment: ratingComment
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer