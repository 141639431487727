import React, { useCallback } from 'react'
import { I18n, hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ form_name = 'collapse_order_detail', record_id, ...props }) => {

  const formProps = {
    record_id,
    name: `${form_name}_${record_id}`,
    model_name: 'order',
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    populate: [
      'source',
      'destination',
      'info',
      'driver_opts',
      'vehicle_opts',
      'assigned_transport',
      'commercial_package',
      'rating',
      'service.contract.company',
      'service.contract',
      'payment_type',
    ],
    initialState: {
      isReadOnly: false
    },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
  }

  const mergedProps = {

  }
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
