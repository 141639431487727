import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'
import moment from 'moment'

import TimeUtils from '@front/volcanion/utils/time'
import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const { groupIndex, isGrouped, row } = props
  const [, { openDialog }] = hooks.useDialogs()
  const [cancel] = hooks.useModelFunction('order', 'cancel')
  const { openNotification } = hooks.useNotification()
  const [openDetail, setOpenDetail] = useState(false)

  const onOpenDetailChange = useCallback(() => {
    setOpenDetail(!openDetail)
  }, [openDetail, setOpenDetail])


  const handleConfirmCancelComment = useCallback(PopupCallbacks.handleCancelConfirmHandler(cancel, openNotification, props), [cancel, openNotification, props])
  const handleOpenCancelConfirmDialog = useCallback(PopupCallbacks.handleOpenConfirmCancelHandler(openDialog), [openDialog])


  const status = _.get(row, 'status')
  const transport_status = _.get(row, 'assigned_transport.status')
  const canCancel = _.includes(['created', 'standby'], status) || _.includes(['attributed'], status) && _.includes(['started', 'planned', 'canceled', 'at_src'], transport_status)
  const hasTitle = groupIndex === 0 && !!isGrouped
  const parsed_status = hooks.useOrderStatus(row, { translated: true })

  const formatted_status = useMemo(() => {
    switch (status) {
      case 'standby':
        return I18n.t('order.status.standby_alter')
      case 'attributed':
        if (_.includes(['at_src', 'on_board', 'in_route', 'at_dest', 'off_board'], transport_status))
          return I18n.t(`order.status.${transport_status}`)
        else if (_.includes(['started'], transport_status) && !!_.get(row, 'current_target.eta.time'))
          return I18n.t('order.status.started', { eta: TimeUtils.secondsToTime(moment(_.get(row, 'current_target.eta.time')).diff(moment(), 'seconds'), null, { hide_seconds: true }) })
      default:
        return parsed_status
    }
  }, [status, parsed_status, transport_status])


  const mergedProps = {
    hasTitle,
    formatted_status,
    openDetail,
    onOpenDetailChange,
    canCancel,
    handleConfirmCancelComment,
    handleOpenCancelConfirmDialog,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer