import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

import GeoUtils from '@front/volcanion/utils/geo'
import { HookUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const [, setFormState] = hooks.useFormState()
  const [service] = hooks.useFieldValues(['service'])
  const [companyservice, status] = hooks.useModel('companyservice', [service], { single: true, populate: ['contract.source_address'] })

  const source = GeoUtils.mergeAddress(companyservice?.contract?.source_address)

  useEffect(() => {
    setFormState({ addressrepository_id: companyservice?.contract?.source_address?.addressrepository_id })
  }, [HookUtils.getLoadingState([status])])

  const mergedProps = {
    source
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer