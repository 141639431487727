import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const [{ company, record_id, contract, isReadOnly }] = hooks.useFormState()
  const requestSubmit = hooks.useGlobalFormFunction('companyuserservices_list', 'requestSubmit')
  const [isFormListReadOnly] = hooks.useGlobalFormState('companyuserservices_list', 'isReadOnly')

  const mergedProps = {
    navigate,
    contract,
    isReadOnlyParentForm: isReadOnly,
    isReadOnly: isFormListReadOnly,
    submitSearchTable: requestSubmit,
    company,
    record_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
