import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'

import { TextField, RatingField } from '@front/squirtle'

const AccountSection = ({ comment, client_id, ...props }) =>
  <Grid container justifyContent='center' rowSpacing={4}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main' }}>
        {I18n.t('rating.evaluation', { client_id })}
      </Typography>
    </Grid>
    <Grid item container xs={12} justifyContent='center'>
      <Grid item xs={4}>
        <RatingField name='rating' />
      </Grid>
    </Grid>
    <Grid item xs={11}>
      <TextField
        label={I18n.t('comment.length.default', { count: (comment || '').length })}
        name={'comment'}
        multiline
        minRows={1}
        InputProps={{
          inputProps: { style: { fontSize: 13 } }
        }}
      />
    </Grid>
  </Grid >

export default React.memo(AccountSection)
