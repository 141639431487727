import React from 'react'
import { I18n, CDNImage } from '@front/volcanion'

import { Grid, Box, Typography } from '@mui/material'
import { AccessTimeFilled } from '@mui/icons-material'

const PinComponent = ({ isSource, source }) => <Box>
  <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
    <CDNImage name={!!isSource ? 'source' : 'destination'} category={'map'} style={{ width: 20 }} />
    <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 300, color: 'text.label' }}>
      {!!isSource ? I18n.t('address.source') : I18n.t('address.no_destination')}
    </Typography>
  </Box>
</Box>

const AddressSection = ({ source }) =>
  <Grid container item xs justifyContent='center' alignItems='center' rowSpacing={1.5}>
    <Grid item xs={12}>
      <Box>
        <PinComponent isSource source={source} />
        <Box sx={{ ml: '9px', borderLeft: 0.3, borderColor: 'gray', pb: 2 }}>
          <Box ml={2} hidden={!source}>
            <Typography fontSize={15}>{source}</Typography>
          </Box>
          <Box ml={2} hidden={!!source}>
            <Typography color='error' fontSize={15}>{I18n.t('address.none')}</Typography>
          </Box>
        </Box>
        <PinComponent />
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AccessTimeFilled color='primary' />
        <Typography fontSize={18} color='text.label' ml={1}>{I18n.t('now')}</Typography>
      </Box>
    </Grid>
  </Grid>

export default React.memo(AddressSection)
