import React from 'react'

import { FormDialog, DialogContent } from '@front/squirtle'
import PackageForm from '@onix/elements/popup/Package'

const DialogManager = ({ onSubmitPackage }) => <>
  <FormDialog name={'package_form'} formProps={{ onSubmit: onSubmitPackage }}>
    <DialogContent>
      <PackageForm />
    </DialogContent>
  </FormDialog>
</>

export default React.memo(DialogManager)
