import React from 'react'
import { I18n, CDNSVG, CDNImage } from '@front/volcanion'
import { useTheme } from '@mui/styles'
import moment from 'moment'

import { Box, Typography, Chip, Divider, } from '@mui/material'
import { TextField } from '@front/squirtle'

import ReservationInfo from './ReservationInfo'


const PinComponent = ({ isSource }) =>
  <Box sx={{ mr: 1 }}>
    <CDNImage name={isSource ? 'source' : 'destination'} category='map' style={{ width: 30, height: 30 }} />
  </Box>

const FirstColumn = ({ dest_datetime, src_datetime, margin, source, destination, mode, duration, hasDuration }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>

      <Box sx={{ display: 'flex', }}>
        <CDNSVG name='car_building' fill={'primary.main'} category={'icons'} />
        <Box sx={{ ml: 2 }} >
          <TextField
            name={'order_client_id'}
            InputProps={{
              startAdornment: <Typography sx={{ fontSize: 13, fontWeight: 600, color: 'text.label', mr: 1 }}>  {I18n.t('order.label.alter')} </Typography>,
              inputProps: { style: { fontSize: 15, fontWeight: 600, WebkitTextFillColor: theme.palette.primary.main } }
            }}
            disabled
          />
          <Chip label={`${mode} ${!!hasDuration ? `- ${duration}` : ''}`} />
        </Box>
      </Box>

      <Box sx={{ flex: 1, display: 'flex' }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <PinComponent isSource />
          <Box sx={{ flex: 1 }}>
            <Divider sx={{ mr: 1 }} orientation='vertical' />
          </Box>
          <PinComponent />
        </Box>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

          <Box>
            <Typography fontSize={13}>{source}</Typography>
            <ReservationInfo />
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 600, color: 'text.label' }}> {I18n.t('date.hour.start')} : </Typography>
              </Box>
              <Box>
                <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 200, color: 'text.input' }}> {moment(src_datetime).format('HH:mm')} </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box hidden={!margin} >
                <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 600, color: 'text.label' }}> {I18n.t('traffic_alert.label.one')} : </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box hidden={!margin} >
                  <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 600, color: 'text.label' }}> {I18n.t('traffic_alert.label.one')} : </Typography>
                </Box>
                <Box hidden={!margin} >
                  <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 200, color: 'text.input' }}>{margin} {I18n.t('minute.label.short')} </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography fontSize={13}>{destination}</Typography>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 600, color: 'text.label' }}> {I18n.t('date.hour.end')} : </Typography>
              </Box>
              <Box sx={{ visibility: !!dest_datetime ? 'visible' : 'hidden' }}>
                <Box>
                  <Typography sx={{ mr: 1, fontSize: 13, fontWeight: 200, color: 'text.input' }}> {moment(dest_datetime).format('HH:mm')} </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

      </Box>

    </Box >

  )
}

export default React.memo(FirstColumn)
