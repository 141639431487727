import moment from 'moment'

import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'
import GeoUtils from "@front/volcanion/utils/geo"
import TimeUtils from '@front/volcanion/utils/time'

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyForm() {

      return {
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        order_client_id: _.get(record, 'order_client_id'),
        source: GeoUtils.format(_.get(record, 'source')),
        destination: !!_.get(record, 'destination') ? GeoUtils.format(_.get(record, 'destination')) : I18n.t('address.destination_not_filled'),
        requestedAt: TimeUtils.getDetailsMomentFront(record?.requestedAt, record?.source),
        schedule_type: _.get(record, 'schedule_type'),
        info: {
          src_datetime: _.get(record, 'info.src_datetime'),
          dest_datetime: _.get(record, 'info.dest_datetime')
        },
        rating: _.get(record, 'rating'),
        driver_opts: _.map(_.get(record, 'driver_opts'), 'user_option_id'),
        vehicle_opts: _.map(_.get(record, 'vehicle_opts'), 'vehicle_option_id'),
        commercial_package: _.get(record, 'commercial_package'),
        driver: _.get(record, 'assigned_transport.driver'),
        vehicle: _.get(record, 'assigned_transport.vehicle'),
        customer_ref: _.get(record, 'customer_ref'),
        company: _.get(record, 'service.contract.company.name'),
        contract: _.get(record, 'service.contract.name'),
        service: _.get(record, 'service.name'),
        driver_comment: _.get(record, 'driver_comment')
      }
    }
  }
  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        load_type: _.get(record, 'commercial_package.load_type'),
        rating: _.get(record, 'rating.rating'),
        ratingComment: _.get(record, 'rating.comment'),
        client_id: _.get(record, 'order_client_id')
      }
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        single: true,
        override_multiple_order_check: true,
        load_type: 'user',
        load_count: 1,
        application: _.get(values, 'user.application')
      })
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const { user_id, companyservice } = state

      return {
        do: user_id,
        source: {
          ..._.pick(_.get(values, 'source.details'), _.flatten([GeoUtils.getAddressKeys(), ['name', 'owner']]))
        },
        loads: {
          src_contact: _.join([_.get(values, 'last_name'), _.get(values, 'first_name')], ' '),
          src_phone: !!_.has(values, 'passenger_number') ? FormatUtils.parsePhoneNumber(_.get(values, 'passenger_number')) : undefined
        },
        calling_number: _.get(values, 'passenger_number'),
        requestedAt: moment.utc().format(),
        commercial_package: _.get(values, 'commercial_package'),
        schedule_type: _.get(state, 'schedule_type'),
        confirmed: true,
        service: companyservice
      }
    }
  }

  static onSubmitSuccessHandler(openResumeOrderPopup) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification() {
      return [I18n.t('notification.order.failed'), { variant: 'error' }]
    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotification() {
      return [I18n.t('notification.order.success'), { variant: 'success' }]
    }
  }
}

export default Callbacks
