import React, { useCallback, useMemo } from 'react'

import { TableCell, Typography } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

import StatusUtils from '@onix/utils/status'

const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const defaultTypographyProps = {
  sx: {
    fontSize: 15,
    textAlign: 'center',
    color: 'text.label'
  }
}

const Row = ({ row, rowId, isSelected, company, ...props }) => {

  const status = useMemo(() => {
    if (rowId === '_create') return 'active'
    else return !!_.get(row, 'companyuser.user.auth.deleted')
      ? 'suspended'
      : !!_.get(row, 'companyuser.user.auth.active')
        ? 'active'
        : 'inactive'
  }, [_.get(row, 'companyuser.user.auth.deleted'), _.get(row, 'companyuser.user.auth.active')])

  return (
    <>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'c_user'}
          model_name='companyuser'
          labelKeys={['contact.last_name']}
          searchMode='search'
          allowNone={false}
          config={{
            populate: ['contact'],
            forced_filter: { company },
            validate_filter: useCallback(() => !!company, [company]),
            watchers: [company]
          }}
          disabled={rowId !== '_create'}
          loadOnFocus
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'c_user'}
          model_name='companyuser'
          labelKeys={['user.info.first_name']}
          searchMode='search'
          allowNone={false}
          disabled
          config={{
            populate: ['user.info']
          }}
          selectProps={{ IconComponent: null }}
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <Typography {..._.merge({}, defaultTypographyProps, { sx: { color: StatusUtils.getColor(status) } })}> {status} </Typography>
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'c_user'}
          model_name='companyuser'
          labelKeys={['user.auth.gsm']}
          searchMode='search'
          allowNone={false}
          disabled
          config={{
            populate: ['user.auth']
          }}
          selectProps={{ IconComponent: null }}
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'c_user'}
          model_name='companyuser'
          labelKeys={['user.auth.email']}
          searchMode='search'
          allowNone={false}
          disabled
          config={{
            populate: ['user.auth']
          }}
          selectProps={{ IconComponent: null }}
        />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
