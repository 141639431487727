import React, { useCallback } from 'react'

import { ModelForm } from '@front/volcanion'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ company, contract }) => {
  const { companyservice_id } = hooks.useParams()
  const requestSubmitSchedule = hooks.useGlobalFormFunction('companyserviceschedules_list', 'requestSubmit')
  const requestSubmitCompanyservice = hooks.useGlobalFormFunction('companyuserservices_list', 'requestSubmit')

  const formProps = {
    record_id: companyservice_id,
    name: `companyservice_form`,
    model_name: 'companyservice',
    populate: [
      'driver_options',
      'vehicle_options',
      'customerref_option.refs',
      'companyservice_schedules.schedule',
      'comments.type',
      'contact',
      'contract'
    ],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(company, contract), [company, contract]),
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(company, contract), [company, contract]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(contract), [contract]),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(requestSubmitCompanyservice, requestSubmitSchedule), [requestSubmitCompanyservice, requestSubmitSchedule]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    redirectOnGenerate: false
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
