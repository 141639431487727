import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const navigate = hooks.useNavigate()
  const [{ client_id, contract, name, record_id }] = hooks.useFormState()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const administration_level = hooks.useAdministrationLevelUser(selectedCompany, contract)

  useEffect(() => {
    !!isReady && navigate('/admin/contracts')
  }, [selectedCompany])

  const mergedProps = {
    record_id,
    administration_level,
    name,
    client_id
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
