import ScheduleUtils from '@onix/utils/schedule'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {

      }
    }
  }
  static formToFilterHandler(service) {
    return function formToFilter(values, extra, meta, state) {
      return {
        service,
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record,
        schedule: {
          schedule_id: record?.schedule.schedule_id,
          ...ScheduleUtils.loadSchedule(_.get(record, 'schedule')),
          start_time: _.get(_.get(record, 'schedule'), 'start_time'),
          end_time: _.get(_.get(record, 'schedule'), 'end_time')
        }
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        planned_authorized: true,
        immediate_authorized: true,
        priority: 0,
        schedule: {
          start_time: '00:00',
          end_time: '23:59',
          applicable_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        }
      }
    }
  }

  static formToRecordHandler(service) {
    return function formToRecord(values, extra, meta) {
      return {
        priority: _.get(values, 'priority'),
        planned_authorized: _.get(values, 'planned_authorized'),
        immediate_authorized: _.get(values, 'immediate_authorized'),
        schedule: ScheduleUtils.exportSchedule({ ..._.get(values, 'schedule'), type: 'service' }),
        service: service || extra?.companyservice,
      }
    }
  }

  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta, state) {
      return [I18n.t('schedule.associated_failed'), { variant: 'error' }]
    }
  }
  static getSuccessNotificationHandler() {
    return function getSuccessNotification(err, values, extra, meta, state) {
      const { omit_success_notification } = extra
      return !!omit_success_notification ? [] : [I18n.t('schedule.associated_success'), { variant: 'success' }]
    }
  }
}

export default Callbacks

