import React from 'react'

import { TextField } from '@front/squirtle'
import { Box, Collapse } from '@mui/material'

const SearchForm = props =>
  <Collapse in timeout={1000}>
    <>
      <Box sx={{ display: 'none' }}><TextField name='text' /></Box>
    </>
  </Collapse>



export default React.memo(SearchForm)
