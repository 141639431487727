import React from 'react'

import { Box, Grid } from '@mui/material'

import { RatingSection, FirstSection, SecondSection, ThirdSection } from './Section/'

const CollapseDetail = ({ tab, status, ...props }) =>
  <Box>
    <Box color={'primary'} sx={{ p: 1, borderRadius: 2, border: 1, m: 1 }}>
      <FirstSection />
    </Box >
    <Box color={'primary'} sx={{ p: 1, borderRadius: 2, border: 1, m: 1 }}>
      <SecondSection />
    </Box >
    <Box sx={{ m: 1 }}> <ThirdSection /></Box>
    <Box sx={{ m: 1 }} hidden={tab !== 3 || !_.includes(['complete'], status)}> <RatingSection /> </Box>
  </Box >

export default React.memo(CollapseDetail)
