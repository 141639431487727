import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import BillingUtils from '@front/volcanion/utils/billing'

import Callbacks from '../../callbacks'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const { openNotification } = hooks.useNotification()
  const [displayDownloadButton, setDisplayDownloadButton] = hooks.useFormState('displayDownloadButton')

  const [order, { isLoading: isLoadingOrder }] = hooks.useModel('order', [record_id], {
    populate: ['quotation', 'assigned_transport.payment', 'payment_type'],
    single: true
  })
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const [isDownloadAvailable] = hooks.useModelFunction('order', 'isDownloadAvailable')

  const customer_ref = _.get(order, 'customer_ref') || '-'
  const payment_type = _.get(order, 'payment_type.name_translated')
  const total = _.includes(['complete', 'canceled'], _.get(order, 'status'))
    ? BillingUtils.getPaymentDisplay(_.get(order, 'assigned_transport.payment'), false, 1)
    : BillingUtils.getEstimationDisplay(_.get(order, 'quotation'), false, 1)
  const discount = _.get(order, 'quotation.discount')

  const handleInvoiceDownload = useCallback(Callbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])
  const isDownloadAvailableStatus = useCallback(Callbacks.isDownloadAvailableStatusHandler(isDownloadAvailable, setDisplayDownloadButton), [isDownloadAvailable, setDisplayDownloadButton])


  useEffect(() => {
    if (!!record_id) {
      isDownloadAvailableStatus(record_id)
    }
  }, [record_id])


  const mergedProps = {
    customer_ref,
    payment_type,
    total,
    discount,
    handleInvoiceDownload,
    displayDownloadButton
  }

  return (
    <Loader isLoading={!!isLoadingOrder}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}
export default withContainer