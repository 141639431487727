import UserUtils from '@front/volcanion/utils/user'

class Callbacks {
  static submitPackageFormPopupHandler(setCommercialPackage) {
    return async function submitPackageFormPopup(values, extra, meta, state) {
      const { commercial_package } = values
      setCommercialPackage(commercial_package)
    }
  }

  static openPackageFormPopupHandler(openDialog, isPackageLoading, cp_ids, commercial_package, onSubmit) {
    return function openPackageFormPopup() {
      openDialog('package_form', {
        isPackageLoading,
        cp_ids,
        allowVehicleCount: true,
        enableFooter: false,
        formProps: { onSubmit, initialValues: { commercial_package } }
      })
      return {}
    }
  }

}

export default Callbacks
