import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static handleCancelConfirmHandler(cancel, openNotification) {
    return async function handleCancel(value, extra, meta, state) {
      const { order_id } = value || {}
      try {
        await cancel({ order_id })
        openNotification(I18n.t('toast.cancel.success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('toast.cancel.error'), { variant: 'error' })
      }
    }
  }
  static handleOpenConfirmCancelHandler(openDialog) {
    return function handleOpenConfirmCancel(order_id) {
      openDialog('cancel_order', { title: I18n.t('order.confirm_cancel') }, { order_id })
    }
  }

}


export default PopupCallbacks
