import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Box } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

import { OptionUtils } from '@front/volcanion/utils'

import FirstColumn from './Column/First'
import SecondColumn from './Column/Second'
import BasicCard from '@onix/elements/package/basic'

const FirstSection = ({ commercial_package_id, isOrderFinished, ...rest }) => (
  <Grid container spacing={1}>
    <Grid item xs>
      <FirstColumn {...rest} />
    </Grid>
    <Grid item xs>
      <SecondColumn {...rest} />
    </Grid>
    <Grid item xs>
      <BasicCard cp_id={commercial_package_id} />
      <Box sx={{ p: 2 }}>
        <ModelMenuSelectorField
          name={'driver_opts'}
          label={I18n.t('option.driver')}
          model_name={'useroption'}
          config={{
            forced_filter: { customer_display: true },
            mutator: elem => ({ label: OptionUtils.getLabel(elem, !isOrderFinished), ...elem })
          }}
          multiple
          disabled
          valueDecoratorProps={{
            sx: { fontSize: 10 }
          }}
          labelProps={{ sx: { fontSize: 16, fontWeight: 600 } }}
        />
        <ModelMenuSelectorField
          name={'vehicle_opts'}
          label={I18n.t('option.vehicle')}
          model_name={'vehicleoption'}
          config={{
            forced_filter: { customer_display: true },
            mutator: elem => ({ label: OptionUtils.getLabel(elem, !isOrderFinished), ...elem })
          }}
          multiple
          disabled
          valueDecoratorProps={{
            sx: { fontSize: 10 }
          }}
          labelProps={{ sx: { fontSize: 16, fontWeight: 600 } }}
        />
      </Box>
    </Grid>
  </Grid>
)

export default React.memo(FirstSection)
