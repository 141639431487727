import React, { useEffect, useCallback } from 'react'
import { hooks } from '@front/volcanion'

import HookUtils from '@front/volcanion/utils/hooks'

import moment from 'moment'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()

  const [service] = hooks.useFieldValues(['service'])
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const [{ addressrepository_id, commercial_package }] = hooks.useFormState()

  const [, setCommercialPackage] = hooks.useFormState('commercial_package')

  const [[ridemode], , ridemode_status] = hooks.useModelSearch('ridemode', 'get', { initial_filter: {}, sort: ['display_index ASC'] })
  const [packages, getAuthorized, packages_status] = hooks.useModelValues('commercialpackage', 'getAuthorized')

  const isLoadingRidemode = HookUtils.getLoadingState([ridemode_status])
  const isLoadingPackage = HookUtils.getLoadingState([packages_status])

  const getPaymentTypeFilter = useCallback(() => ({ service_id: service, load_type: 'user' }), [service])

  const cp_ids = _.sortBy(_.map(packages, 'commercial_package_id'), 'display_index')
  const first_package = _.head(cp_ids)

  const submitPackageFormPopup = useCallback(Callbacks.submitPackageFormPopupHandler(setCommercialPackage), [setCommercialPackage])
  const openPackageFormPopup = useCallback(Callbacks.openPackageFormPopupHandler(openDialog, isLoadingPackage, cp_ids, commercial_package, submitPackageFormPopup), [openDialog, isLoadingPackage, cp_ids, commercial_package, submitPackageFormPopup])

  const onSubmit = useCallback(() => requestSubmit({ ridemode: ridemode?.ridemode_id }), [isLoadingRidemode])

  useEffect(() => {
    if (!addressrepository_id || !ridemode?.ridemode_id || !service) return
    getAuthorized({
      source: { addressrepository_id },
      destination: null,
      load_type: 'user',
      service,
      requestedAt: moment.utc().format(),
      mode: ridemode?.ridemode_id
    })
  }, [addressrepository_id, service, isLoadingRidemode])

  useEffect(() => {
    setCommercialPackage(first_package)
  }, [first_package])


  const mergedProps = {
    isSubmitting,
    onSubmit,
    commercial_package,
    service,
    getPaymentTypeFilter,
    openPackageFormPopup
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer