import React from 'react'
import { hooks } from '@front/volcanion'
import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [source, destination] = hooks.useFieldValues(['source', 'destination'])
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['assigned_transport.info', 'info', 'mode', 'source', 'destination'] })

  const src_datetime = TimeUtils.getDetailsMomentFront(_.get(order, 'assigned_transport.info.arrivedAt') || _.get(order, 'requestedAt'), order?.source)
  const dest_datetime = TimeUtils.getDetailsMomentFront(_.get(order, 'assigned_transport.info.dropoffAt') || _.get(order, 'info.dest_datetime'), order?.destination)


  console.log('Edouard : ', { source, destination, src_datetime })
  const mergedProps = {
    hasDuration: order?.info?.duration,
    duration: TimeUtils.secondsToTime(order?.info?.duration, 'hh[h]mm[min]'),
    mode: order?.mode?.name,
    source,
    destination,
    margin: _.get(order, 'info.margin'),

    record_id,
    src_datetime,
    dest_datetime
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer