import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [commercial_package] = hooks.useFieldValues(['commercial_package'])

  const commercial_package_id = _.get(commercial_package, 'commercial_package_id') || commercial_package
  const [order] = hooks.useModel('order', [record_id], { single: true })

  const mergedProps = {
    isOrderFinished: _.includes(['canceled', 'complete'], order?.status),
    commercial_package_id
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer