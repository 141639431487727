import React, { useCallback, useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'
import { TableRow, SearchFrame, SearchForm, SearchTable, FormContent, TableEmpty } from '@front/squirtle'

import Callbacks from './callbacks'
import { Typography, Box } from '@mui/material'

import Row from './Row'
import Form from './Form'

const NoResultComponent = props => {
  const [tab] = hooks.useGlobalState('tab')

  const no_result_label = useMemo(() => {
    switch (tab) {
      case 1:
        return I18n.t('order.in_progress.label', { count: 1 })
      case 2:
        return I18n.t('order.planneds.label', { count: 1 })
      case 3:
        return I18n.t('order.passed.label', { count: 1 })
      default:
        return I18n.t('order.label.other_alter')
    }
  }, [tab])
  return <Box height={200} display='flex' justifyContent='center' alignItems='center'>
    <Typography fontSize={'4vw'} variant={'h3'} opacity={0.8} align='center' color='primary'>{`${I18n.t('none_fem')} ${_.toLower(no_result_label)}`}</Typography>
  </Box>
}

const Orders = ({
  disableButton = true,
  allowCreate = true,
  FormComponent = Form,
  groupBy,
  submitWatchers = [],
  forcedFormToFilter = {},
  isB2B = false
}) => {

  const navigate = hooks.useNavigate()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(forcedFormToFilter), [forcedFormToFilter])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])

  const [tab] = hooks.useGlobalState('tab')

  return <Box>
    <SearchFrame
      model_name={'order'}
      populate={[
        'service',
        'quotation',
        'loads',
        'assigned_transport.vehicle.info',
        'source',
        'info',
        'commercial_package',
        'mode',
        'current_target.eta'
      ]}
      sort={['requestedAt DESC']}
      default_limit={20}
      search_id={'order_list'}
      allowCreate={allowCreate}
      createButtonProps={{
        label: I18n.t('order.create'),
        onClick: () => navigate('/booking')
      }}
    >
      <SearchForm
        submitOnMount
        submitWatchers={submitWatchers}
        disableButton={disableButton}
        getButtonStyleProps={() => ({ mt: 2, mb: 4 })}
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
        formToOptions={useCallback(() => ({ type: !!isB2B ? undefined : 'private' }), [isB2B])}
        allowCreate={false}
      >
        <FormContent><FormComponent /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          enableRowClick: false,
          placementPagination: 'bottom',
          groupBy,
          enablePagination: true,
          onRowClick: null
        }}
      >
        <TableEmpty><NoResultComponent /></TableEmpty>
        <TableRow isGrouped={!!groupBy} tab={tab}><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  </Box >
}

export default React.memo(Orders)
