import React, { useMemo } from 'react'
import moment from 'moment'
import { hooks, I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'
import TimeUtils from '@front/volcanion/utils/time'

const withContainer = Component => props => {
  const [driver_opts, vehicle_opts] = hooks.useFieldValues(['driver_opts', 'vehicle_opts'])
  const [record_id] = hooks.useFormState('record_id')

  const [order] = hooks.useModel('order', [record_id], {
    populate: ['info', 'source'],
    single: true
  })

  const [driver_options] = hooks.useModel('useroption', driver_opts)
  const [vehicle_options] = hooks.useModel('vehicleoption', vehicle_opts)
  const options_formatted = useMemo(() => _.join(_.compact(_.flatten([
    _.map(_.filter(driver_options, ['customer_display', true]), 'name_translated'),
    _.map(_.filter(vehicle_options, ['customer_display', true]), 'name_translated')
  ])), ', '), [driver_options, vehicle_options])


  const requestedAt = FormatUtils.formatDateBackToFront(TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source))
  const schedule_type = !!_.get(order, 'schedule_type') && I18n.t(`schedule.${_.get(order, 'schedule_type')}`)
  const diff_time = moment(_.get(order, 'info.src_datetime')).diff(moment(_.get(order, 'requestedAt')), 'seconds')
  const waiting_time = diff_time > 0 ? TimeUtils.secondsToTime(diff_time, 'HH[h]mm[min]') : '-'

  const load_count = _.get(order, 'passenger_count') || _.get(order, 'package_count')
  const load_type = _.get(order, 'commercial_package.load_type') || 'user'
  const luggage = I18n.t(!!_.get(order, 'has_luggage') ? 'with' : 'without')

  const mergedProps = {
    requestedAt,
    schedule_type,
    waiting_time,
    load_count,
    load_type,
    luggage,
    options_formatted
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer