import React, { useEffect, useState } from 'react'
import { I18n, hooks, CDNImage } from '@front/volcanion'

import { Fade, Box, Grid, TableCell, Typography, IconButton, Collapse, Button, Icon } from '@mui/material'
import { ConfirmationDialog } from '@front/squirtle'

import { AccessTime, Work, Person, Visibility, VisibilityOff, LocalShippingOutlined, LocalTaxiOutlined } from '@mui/icons-material'

import FormatUtils from '@front/squirtle/utils/format'
import GeoUtils from "@front/volcanion/utils/geo"

import RowCollapse from '../Collapse'
import { TimeUtils } from '@front/volcanion/utils'

const TitleColumn = ({ hasTitle, rowId, ...props }) =>
  <Box sx={{ bgcolor: 'extraWhite', width: 'fit-content', p: 1, boxShadow: 3, display: !!hasTitle ? 'block' : 'none' }}>
    <Typography color={'primary'} fontSize={'1.1vw'} textAlign={'center'} fontWeight={600}>
      {_.upperFirst(rowId)}
    </Typography>
  </Box >

const DateColumn = ({ requestedAt }) =>
  <Grid item xs hidden={!requestedAt}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccessTime color={'primary'} />
      <Typography color={'primary'} ml={1} fontSize={'1vw'}>
        {FormatUtils.formatDateBackToFront(requestedAt, 'DD/MM/YYYY [à] HH:mm')}
      </Typography>
    </Box>
  </Grid>

const DiscountColumn = ({ discount }) =>
  <Grid item xs hidden={!discount}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Person color={'primary'} fontSize='large' />
      <Typography color={'primary'} ml={1} fontSize={'1vw'}>
        {discount}
      </Typography>
    </Box>
  </Grid>

const ServiceColumn = ({ service }) =>
  <Grid item xs hidden={!service}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Work color={'primary'} />
      <Typography color={'primary'} ml={1} fontSize={'1vw'}>
        {service}
      </Typography>
    </Box>
  </Grid>

const PassengerColumn = ({ passenger_name }) =>
  <Grid item xs hidden={!passenger_name}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Person color={'primary'} />
      <Typography color={'primary'} ml={1} fontSize={'1vw'}>
        {passenger_name}
      </Typography>
    </Box>
  </Grid>

const SourceColumn = ({ source }) =>
  <Grid item hidden={!source} >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CDNImage name={'source'} category={'map'} style={{ width: 30 }} />
      <Typography sx={{ ml: 1, fontSize: '1vw', color: 'text.label' }}>
        {GeoUtils.format(source)}
      </Typography>
    </Box >
  </Grid>

const PlateColumn = ({ plate }) =>
  <Grid item hidden={!plate}>
    <Box sx={{ display: 'flex' }}>
      <Typography color={'text.input'} fontSize={'1vw'}>{'-'}</Typography>
      <Typography color={'text.input'} ml={1} mr={1} fontSize={'1vw'}>{_.upperFirst(I18n.t('vehicle.plate_bis'))}</Typography>
      <Typography color={'primary'} fontSize={'1vw'}>{plate}</Typography>
    </Box>
  </Grid>

const OpenActionColumn = ({ openDetail, onOpenDetailChange, ...props }) =>
  <Grid item xs>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton color={'primary'} onClick={onOpenDetailChange} disableRipple>
        <Icon fontSize='1vw'>{!openDetail ? 'visibility' : 'visibility_off'}</Icon>
      </IconButton>
    </Box>
  </Grid>

const StatusColumn = ({ row, formatted_status, canCancel, handleOpenCancelConfirmDialog, load_type }) => {
  const [refreshFade, setRefreshFade] = useState(false)

  useEffect(() => {
    setRefreshFade(false)
    setRefreshFade(true)
  }, [formatted_status])

  return (
    <Grid container >
      <Grid item>
        <IconButton color={'primary'} disableRipple>
          {
            load_type === 'user'
              ? <LocalTaxiOutlined sx={{ fontSize: '2vw' }} color='primary' />
              : <LocalShippingOutlined sx={{ color: 'orange', fontSize: '2vw' }} />
          }
        </IconButton>
      </Grid>
      <Grid item>
        <Fade in={refreshFade} timeout={3000}>
          <Typography noWrap sx={{ ml: 1, fontSize: '1vw', fontWeight: 550, letterSpacing: 1.5, color: hooks.useOrderStatusColor(row), whiteSpace: 'initial' }}>
            {formatted_status}
          </Typography>
        </Fade>
      </Grid>
      <Grid item >
        <Button onClick={() => handleOpenCancelConfirmDialog(row.order_id)} color='error' variant='contained' size={'small'}>
          <Typography sx={{ fontSize: '0.7vw' }}>
            {I18n.t('action.cancel')}
          </Typography>
        </Button>
      </Grid>
    </Grid>

  )
}

const Row = ({
  row,
  rowId,
  handleConfirmCancelComment,
  handleOpenCancelConfirmDialog,
  canCancel,
  openDetail,
  onOpenDetailChange,
  formatted_status,
  hasTitle,
  tab,
  ...props
}) => {
  const order_id = _.get(row, 'order_id')
  const discount = _.get(row, 'quotation.discount')
  const requestedAt = TimeUtils.getDetailsMomentFront(_.get(row, 'requestedAt'), _.get(row, 'source'))
  const source = _.get(row, 'source')
  const service = _.get(row, 'service.name')
  const load = _.head(_.get(row, 'loads'))
  const load_type = _.get(row, 'commercial_package.load_type')
  const passenger_name = _.get(load, 'src_contact')
  const plate = _.get(row, 'assigned_transport.vehicle.info.plaque')

  const [refreshFade, setRefreshFade] = useState(false)

  useEffect(() => {
    setRefreshFade(false)
    setRefreshFade(true)
  }, [formatted_status])

  return <>
    <ConfirmationDialog name={'cancel_order'} onConfirm={handleConfirmCancelComment} />
    <TableCell sx={{ p: '1px', pt: !!hasTitle ? 3 : 0 }}>
      <TitleColumn hasTitle={hasTitle} rowId={rowId} />

      <Box sx={{ p: 1, borderRadius: 1 }} >
        <Grid container alignItems='center' rowSpacing={1}>
          <DateColumn requestedAt={requestedAt} />
          <ServiceColumn service={service} />
          <DiscountColumn discount={discount} />
          <PassengerColumn passenger_name={passenger_name} />

          <Grid container item xs={12}>
            <Button sx={{ boxShadow: 3, borderRadius: 1, pr: 2, width: 1, textTransform: 'none' }} onClick={onOpenDetailChange}>
              <Grid container alignItems='center'>
                <Grid container item xs={11} alignItems='center' spacing={2}>
                  <Grid item>
                    <IconButton color={'primary'} disableRipple>
                      {
                        load_type === 'user'
                          ? <LocalTaxiOutlined sx={{ fontSize: '2vw' }} color='primary' />
                          : <LocalShippingOutlined sx={{ color: 'orange', fontSize: '2vw' }} />
                      }
                    </IconButton>
                  </Grid>
                  <Grid item >
                    <Fade in={refreshFade} timeout={3000}>
                      <Typography fontSize={'1.2vw'} textAlign='center' fontWeight={550} letterSpacing={1.5} color={hooks.useOrderStatusColor(row)}>
                        {formatted_status}
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid item>
                    <Box hidden={!canCancel}>
                      <Button onClick={() => handleOpenCancelConfirmDialog(row.order_id)} color='error' variant='contained' size={'small'} sx={{ fontSize: '0.8vw' }} hidden={!canCancel}>
                        {I18n.t('action.cancel')}
                      </Button>
                    </Box>
                  </Grid>
                  <SourceColumn source={source} />
                  <PlateColumn plate={plate} />
                </Grid>
                <OpenActionColumn openDetail={openDetail} onOpenDetailChange={onOpenDetailChange} />
              </Grid>
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!!openDetail && <Collapse in={openDetail}> <RowCollapse record_id={order_id} tab={tab} /></Collapse>}
          </Grid>
        </Grid>
      </Box>
    </TableCell>
  </>
}

export default React.memo(Row)
