import moment from 'moment'

const exportDate = (dateValue, hours) =>
  !!dateValue ? moment(dateValue, 'DD/MM/YYYY HH:mm').set(hours).toISOString(true) : undefined

const formatRequestedAt = (start, end) => {
  if (!!start && !!end) return { '>=': start, '<=': end }
  else if (!!start && !end) return { '>=': start }
  else if (!start && !!end) return { '<=': start }
  else return undefined
}

const Aux = {
  exportDate,
  formatRequestedAt
}

export default Aux
