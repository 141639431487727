import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [load_type] = hooks.useFormState('load_type')
  const [service] = hooks.useFieldValues(['service'])

  const mergedProps = {
    load_type,
    isB2B: !!service
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer