import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const companyuserservice_id = _.get(row, 'companyuserservice_id')
  const [deleteRecord] = hooks.useModelFunction('companyuserservices', 'delete')
  const onDelete = useCallback(() => deleteRecord({ companyuserservice_id })
    , [companyuserservice_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
