
class PopupCallbacks {
  static onSubmitPackageHandler(setFormState) {
    return async function onSubmitPackage(values, extra, meta, state) {
      const { commercial_package } = values
      setFormState({ commercial_package })
    }
  }

}

export default PopupCallbacks
