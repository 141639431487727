import React from 'react'
import { I18n } from '@front/volcanion/'
import { useTheme } from '@mui/styles'
import { Box, Grid, Typography } from '@mui/material'

import { TextField, MenuSelectorField, ModelAutocompleteField, SingleOptionSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography color='primary' fontSize={15} fontWeight={600}>{I18n.t('information.general')} </Typography>
  </Grid>

const Row1 = ({ color, deferred_billing_options }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'name'}
        label={I18n.t('name.label', { count: 1 })}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'client_id'}
        label={I18n.t('companyservice.code')}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.email'}
        label={I18n.t('email.label', { count: 1 })}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.inactive'), value: 'inactive' },
          { label: I18n.t('status.archived'), value: 'archived' },
          { label: I18n.t('status.suspended'), value: 'suspended' },
        ]}
        valueDecoratorProps={{
          sx: {
            WebkitTextFillColor: color,
            fontWeight: 600
          }
        }}
        selectProps={{ IconComponent: null }}
        allowNone={false}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('payment.deferred', { count: 1 })}
        name={'deferred_billing'}
        options={deferred_billing_options}
        selectFirst
        allowNone={false}
      />
    </Grid>
  </Grid>

const Row2 = ({ DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER }) => {
  const theme = useTheme()
  return (
    <Grid container item xs={12} alignItems='flex-end' spacing={1}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center'>
          <Typography color='primary' fontSize={15} fontWeight={600}>{I18n.t('call.label', { count: 1 })} </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        {!!CLIENT_CAN_CALL_DRIVER && <SingleOptionSelectorField
          name={'can_call_booker'}
          optionLabel={I18n.t('call.client_driver')}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } },
            labelPlacement: 'end',
            sx: { pl: '3px' }
          })}
        />}
        {!!DRIVER_CAN_CALL_CLIENT && <SingleOptionSelectorField
          name={'can_call_driver'}
          optionLabel={I18n.t('call.driver_client')}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } },
            labelPlacement: 'end',
            sx: { pl: '3px' }
          })}
        />}
      </Grid>
      <Grid item xs>
        <SingleOptionSelectorField
          name={'customerref_option.imposed_ref'}
          optionLabel={I18n.t('ref.billing.mandatory')}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } },
            labelPlacement: 'end'
          })}
        />
      </Grid>
      <Grid item xs>
        <MenuSelectorField
          name={'customerref_option.fill_ref_rule'}
          label={I18n.t('ref.billing.fill_ref')}
          selectFirst
          required
          options={[
            { label: I18n.t('ref.options.optional'), value: 'optional' },
            { label: I18n.t('ref.options.suggested'), value: 'suggested' },
            { label: I18n.t('ref.options.required'), value: 'required' }
          ]}
        />
      </Grid>
      <Grid item xs>
        <ModelAutocompleteField
          name={'customerref_option.refs'}
          label={I18n.t('ref.authorized.list')}
          model_name={'commercialformula'}
          labelKeys={['client_id']}
          multiple
          creable
          createKey={['client_id']}
        />
      </Grid>
    </Grid >
  )
}

const Primary = props => <>
  <Box sx={{ p: 2, border: 1, borderColor: 'primary.main', borderRadius: 1 }}>
    <Grid container rowSpacing={2}>
      <Title />
      <Row1 {...props} />
      <Row2 {...props} />
    </Grid>
  </Box>
</>

export default React.memo(Primary)
