import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button, Collapse, Grid, Typography } from '@mui/material'

import { TextField, MenuSelectorField } from '@front/squirtle'

const SearchForm = ({ requestSubmit, handleCreate, ...props }) => <Collapse in={false}>
  <Box sx={{ p: 2, boxShadow: 3, mb: 2 }}>
    <Grid container spacing={3} alignItems='flex-end'>
      <Grid item xs>
        <TextField
          name='name'
          label={I18n.t('name.label', { count: 1 })}
        />
      </Grid>
    </Grid>
  </Box>
</Collapse>

export default React.memo(SearchForm)
