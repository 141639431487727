import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'
import { DirectionsCarFilledOutlined } from '@mui/icons-material'

import { Primary, Address, Package } from './Section/'
import Popup from './Popup'

const Title = props =>
  <Box sx={{ p: '3px', boxShadow: 3, display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '98%', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ ml: 2, color: 'primary.main' }}> <DirectionsCarFilledOutlined fontSize='large' />  </Box>
      <Typography sx={{ ml: 2, fontSize: 'h6.fontSize', color: 'primary.main', letterSpacing: 1.5, fontWeight: 500 }}>
        {I18n.t('order.quick')}
      </Typography>
    </Box>
    <Box>
    </Box>
  </Box >

const QuickOrder = props => <>
  <Popup />
  <Title />
  <Box sx={{ pt: 2, pb: 2 }}>
    <Grid container spacing={5} justifyContent='center'>
      <Primary />
      <Address />
      <Package />
    </Grid>
  </Box>
</>


export default React.memo(QuickOrder)
