
import Aux from './Aux'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name'),
        client_id: _.get(record, 'client_id'),
        status: record.deleted ? 'archive' : record.active ? 'active' : 'inactive',
        deferred_billing: _.get(record, 'deferred_billing'),
        can_call_booker: _.get(record, 'can_call_booker'),
        can_call_driver: _.get(record, 'can_call_driver'),
        contact: {
          email: _.get(record, 'contact.email')
        },
        customerref_option: {
          fill_ref_rule: _.get(record, 'customerref_option.fill_ref_rule'),
          imposed_ref: !_.get(record, 'customerref_option.manual_allowed'),
          refs: _.get(record, 'customerref_option.refs')
        },
        password: _.get(record, 'password'),
        new_password: _.get(record, 'new_password'),
        new_password_availableSince: _.get(record, 'new_password_availableSince'),
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      const company = record?.contract?.company
      return {
        company,
        client_id: _.get(record, 'client_id'),
        name: _.get(record, 'name'),
        contract: record?.contract?.companycontract_id,
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active',
        can_call_booker: false,
        can_call_driver: false,
      }
    }
  }
  static getEmptyStateHandler(company, contract) {
    return function getEmptyStateValues() {
      return {
        company,
        contract,
      }
    }
  }

  static formToRecordHandler(contract) {
    return function formToRecord(values, extra, meta) {
      return {
        name: values?.name,
        client_id: values?.client_id,
        contact: {
          email: values?.contact?.email
        },
        deferred_billing: values?.deferred_billing,
        can_call_booker: values?.can_call_booker,
        can_call_driver: values?.can_call_driver,
        customerref_option: {
          refs: values?.customerref_option?.refs,
          fill_ref_rule: values?.customerref_option?.fill_ref_rule,
          manual_allowed: _.has(values, 'customerref_option.imposed_ref') ? !values?.customerref_option?.imposed_ref : undefined
        },
        password: values?.password,
        new_password: values?.new_password,
        new_password_availableSince: values?.new_password_availableSince,
        contract,
        ...Aux.exportStatus(_.get(values, 'status')),
      }
    }
  }

  static onSubmitSuccessHandler(requestSubmitCompanyservice, requestSubmitSchedule) {
    return async function onSubmitSuccess(result, values, extra, meta, state) {
      const { onClose } = extra
      !!onClose && onClose()
      requestSubmitCompanyservice({ companyservice: result?.companyservice_id, omit_success_notification: true })
      requestSubmitSchedule({ companyservice: result?.companyservice_id, omit_success_notification: true })
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
