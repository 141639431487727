import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        single: true,
        override_multiple_order_check: true,
        load_type: 'user',
        load_count: 1,
        application: _.get(values, 'user.application')
      })
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {

      return {
        source: {
          addressrepository_id: state?.addressrepository_id
        },
        loads: {
          src_contact: values?.passenger_name,
          src_phone: values?.passenger_number
        },
        commercial_package: state?.commercial_package,
        payment_type: values?.payment_type,
        service: values?.service,
        mode: extra?.ridemode,
        confirmed: true,
        schedule_type: 'immediate'
      }
    }
  }

  static onSubmitSuccessHandler(openResumeOrderPopup) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta, state) {
      const passenger_name = _.find(meta, ['field', 'passenger_name'])
      const passenger_number = _.find(meta, ['field', 'passenger_number'])

      if (!state?.addressrepository_id)
        return [I18n.t('address.none'), { variant: 'error' }]
      else if (!!passenger_name?.field_error && !!passenger_number?.field_error)
        return [`${I18n.t('hostel_passenger')} ${I18n.t('prefix.and')} ${_.toLower(I18n.t('gsm.label', { count: 1 }))} ${_.toLower(I18n.t('required'))}`, { variant: 'error' }]
      else if (!!passenger_name?.field_error)
        return [`${I18n.t('hostel_passenger')} ${_.toLower(I18n.t('required'))}`, { variant: 'error' }]
      else if (!!passenger_number?.field_error)
        return [`${I18n.t('gsm.label', { count: 1 })} ${_.toLower(I18n.t('required'))}`, { variant: 'error' }]
      else return [I18n.t('notification.order.failed'), { variant: 'error' }]
    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotificationd() {
      return [I18n.t('notification.order.success'), { variant: 'success' }]
    }
  }
}

export default Callbacks
