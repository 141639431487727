import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static submitRatingPopupHandler(generateRating, record_id, openNotification, setRating) {
    return async function submitRatingPopup(values, extra, meta, state) {
      const { comment, rating } = values
      try {
        if (!!comment && comment.length > 250) {
          openNotification(I18n.t('comment.too_long'), { variant: 'error' })
          throw {}
        }
        await generateRating({
          data: {
            order: record_id,
            rating,
            comment
          }
        })
        openNotification(I18n.t('rating.success'), { variant: 'success' })
        setRating(rating)
      } catch (err) {
        openNotification(I18n.t('rating.error'), { variant: 'error' })
        throw err
      }
    }
  }
  static openRatingPopupHandler(openDialog, client_id) {
    return function openRatingPopup(value) {
      openDialog('rating_form', { client_id, formProps: { initialValues: { rating: value } } })
      return
    }
  }
}

export default PopupCallbacks
