import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Icon, InputAdornment } from '@mui/material'
import { TextField } from '@front/squirtle'

const CustomTextField = ({ name, label, hidden = false, iconName, ...props }) =>
  <Box>
    <Box display={'flex'} alignItems={'center'}>
      <Icon color={'primary'} baseClassName={'material-icons-outlined'}>
        {iconName}
      </Icon>
      <TextField
        name={name}
        InputProps={{
          startAdornment: <InputAdornment disableTypography sx={{ fontSize: 13, fontWeight: 600, color: 'text.label', mr: 1 }}>
            {label}
          </InputAdornment>,
          inputProps: { style: { fontSize: 13, marginTop: 4 } }
        }}
        disabled
      />
    </Box>
  </Box>

const SecondColumn = ({ isB2B, ...props }) =>
  <Box>
    <CustomTextField name={'driver_comment'} label={I18n.t('comment.label', { count: 1 })} minRows={2} multiline />
    <CustomTextField name={'company'} label={`${I18n.t('company.label', { count: 1 })} :`} iconName={'business_center'} hidden={!isB2B} />
    <CustomTextField name={'contract'} label={`${I18n.t('companycontract.label', { count: 1 })} :`} hidden={!isB2B} />
    <CustomTextField name={'service'} label={`${I18n.t('companyservice.label', { count: 1 })} :`} hidden={!isB2B} />
  </Box>

export default React.memo(SecondColumn)
