
import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Stack } from '@mui/material'

import FormHeader from '@onix/elements/headers/form'

import PrimarySection from './Section/Primary'
import ScheduleSection from './Section/Schedule'
import PasswordSection from './Section/Password'
import UsersSection from './Section/Users'

const Service = ({ name, client_id, administration_level, record_id }) =>
  <Stack spacing={2}>
    <FormHeader
      isSvg
      name={'contracts'}
      title={!!record_id
        ? I18n.t('companyservice.label.dynamic', { client_id, name })
        : I18n.t('companyservice.generate')}
      enableActions={_.includes(['company', 'contract'], administration_level) && !!record_id}
    />
    <Box sx={{ boxShadow: 6, borderRadius: 3, p: 2 }}>
      <Grid container justifyContent='center' alignItems='center' rowSpacing={3} spacing={1}>
        <Grid item xs={12}>
          <PrimarySection />
        </Grid>
        <Grid item xs={12}>
          <ScheduleSection />
        </Grid>
        <Grid item xs={12}>
          <PasswordSection />
        </Grid>
        <Grid item xs={12} >
          <UsersSection />
        </Grid>
      </Grid>
    </Box>
  </Stack>

export default React.memo(Service)
