import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Grid, Typography } from '@mui/material'

import { DateField, TextField } from '@front/squirtle'

const Password = (props) =>
  <Box sx={{ p: 2, border: 1, borderColor: 'primary.main', borderRadius: 1 }}>
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs={12}>
        <Typography color='primary' fontSize={15} fontWeight={600}>{I18n.t('password.label', { count: 1 })} </Typography>
      </Grid>
      <Grid item xs>
        <TextField
          name={'password'}
          label={I18n.t('password.current.label.short')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name={'new_password'}
          label={I18n.t('password.new.label.short')}
        />
      </Grid>
      <Grid item xs>
        <DateField
          name={'new_password_availableSince'}
          label={I18n.t('password.current.activation')}
          inputFormat={'DD/MM/YYYY HH:mm'}
          disableOpenPicker
        />
      </Grid>
    </Grid>
  </Box>

export default React.memo(Password)
