import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [, setFormState] = hooks.useFormState()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  // const [company] = hooks.useModel('company', [selectedCompany], { populate: ['contracts.companyservices'], single: true })
  // const services = company?.contracts?.companyservices
  // const passenger_name = company?.name

  const [user] = hooks.useActiveUser({ populate: ['user_companies.services.contact', 'user_companies.company'] })

  const user_company = _.find(user?.user_companies, ['company.company_id', selectedCompany])
  const passenger_name = user_company?.company?.name

  const afterChange = useCallback(value => {
    const passenger_number = _.find(user_company?.services, ['companyservice_id', value])?.contact?.gsm

    setFormState({
      addressrepository_id: undefined
    })
    setFormValues([
      { field: 'passenger_name', value: passenger_name },
      { field: 'passenger_number', value: passenger_number }
    ])
  }, [setFormValues, setFormState, passenger_name, user_company])

  const mergedProps = {
    afterChange,
    selectedCompany
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
