import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')

  const mergedProps = {

  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
