import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography, CardActionArea, Icon } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import BasicCard from '@onix/elements/package/basic'
import { ModelMenuSelectorField } from '@front/squirtle'

const Third = ({ commercial_package, openPackageFormPopup, isSubmitting, onSubmit, getPaymentTypeFilter, service, ...props }) =>
  <Grid container item xs justifyContent='center' rowSpacing={3}>
    <Grid item xs={10}>
      <CardActionArea onClick={openPackageFormPopup}>
        <BasicCard cp_id={commercial_package} />
      </CardActionArea>
    </Grid>
    <Grid item xs={10}>
      <ModelMenuSelectorField
        name={'payment_type'}
        model_name={'paymenttype'}
        searchMode={'search'}
        config={{ mode: 'available', sort: ['display_index ASC'], submitOnUpdate: true }}
        watchers={[service]}
        labelKeys={['name_translated']}
        autocompleteMutator={getPaymentTypeFilter}
        valueDecoratorProps={{
          sx: {
            fontSize: 15,
            color: 'text.label',
            mt: '3px'
          }
        }}
        sx={{ '.MuiSelect-icon': { color: 'primary.main', fontSize: 21 }, width: 1 }}
        loadOnMount
        selectFirst
        required
        variant='outlined'
        requiredText={I18n.t('payment.empty')}
      />
    </Grid>
    <Grid item xs>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingButton
          loading={!!isSubmitting}
          size='large'
          variant='contained'
          onClick={onSubmit}
          color='primary'
          endIcon={<Icon color='extraWhite'>{'arrow_right_alt'}</Icon>}
        >
          <Typography sx={{ color: 'extraWhite' }}> {I18n.t('action.book')} </Typography >
        </LoadingButton>
      </Box>
    </Grid>
  </Grid >

export default React.memo(Third)
