import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Icon, InputAdornment } from '@mui/material'
import { TextComponent } from '@front/squirtle'

const CustomTextField = ({ value, label, hidden = false, iconName, ...props }) =>
  <Grid container item xs={12} alignItems='center' >
    <Grid item xs={1} hidden={hidden}>
      <Icon
        color={'primary'}
        baseClassName='material-icons-outlined'
        className={iconName}
        sx={{ fontSize: 17 }}
      >
        {iconName}
      </Icon>
    </Grid>
    <Grid item xs={11} hidden={hidden}>
      <TextComponent
        value={value}
        InputProps={{
          startAdornment: <InputAdornment position='start' disableTypography sx={{ fontSize: 13, fontWeight: 600, color: 'text.label', mb: '3px', mr: 1 }}>
            {label}
          </InputAdornment>,
          inputProps: { style: { fontSize: 13, minWidth: 50 } }
        }}
        disabled
      />
    </Grid>
  </Grid>

const SecondColumn = ({ requestedAt, schedule_type, waiting_time, options_formatted, load_count, load_type, luggage, ...props }) =>
  <Grid container direction='column' spacing={1}>
    <CustomTextField value={requestedAt} label={I18n.t('date.full')} />
    <CustomTextField value={schedule_type} label={I18n.t('order.label', { count: 1 })} />
    <CustomTextField value={waiting_time} label={I18n.t('date.waiting')} />
    <CustomTextField value={load_count} label={I18n.t(`load.${load_type}`, { count: 1 })} />
    <CustomTextField value={luggage} label={I18n.t('luggage.label', { count: 2 })} />
  </Grid>



export default React.memo(SecondColumn)
