import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell, Box, Typography } from '@mui/material'

const TextHeader = ({ label }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Typography sx={{ color: 'text.label', textAlign: 'center', fontSize: 13, fontWeight: 600 }}>
      {label}
    </Typography>
  </Box>

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.label', { count: 1 })} icon={'calendar_today'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('begin')} icon={'access_time'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('end')} icon={'access_time'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('priority.label', { count: 1 })} icon={'priority_high'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('date.immediate_short')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('date.planned_short')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('day.label', { count: -1 })} />
  </TableCell>
</>

export default React.memo(Header)
