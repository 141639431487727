import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Icon, InputAdornment } from '@mui/material'
import { TextComponent } from '@front/squirtle'
import { AddBoxOutlined } from '@mui/icons-material'

const CustomTextComponent = ({ value, label, hidden = false, iconName, ...props }) =>
  <Box >
    <Box display={'flex'} alignItems={'center'}>
      <Icon color={'primary'} baseClassName={'material-icons-outlined'}>
        {iconName}
      </Icon>
      <TextComponent
        value={value}
        InputProps={{
          startAdornment: <InputAdornment disableTypography sx={{ fontSize: 13, fontWeight: 600, color: 'text.label', mr: 1 }}>
            {label}
          </InputAdornment>,
          inputProps: { style: { fontSize: 13, marginTop: 4 } }
        }}
        disabled
        {...props}
      />
    </Box>
  </Box>

const FirstColumn = ({ formatted_driver, formatted_vehicle, plate_vehicle, ...props }) =>
  <Box>
    <CustomTextComponent value={formatted_driver} label={I18n.t('driver.label', { count: 1 })} iconName={'workspace_premium'} />
    <CustomTextComponent value={formatted_vehicle} label={I18n.t('vehicle.label', { count: 1 })} />
    <CustomTextComponent value={plate_vehicle} label={I18n.t('vehicle.plate', { count: 1 })} />
  </Box>

export default React.memo(FirstColumn)
