import React from 'react'
import { I18n } from '@front/volcanion/'

import { TableCell } from '@mui/material'
import { TextField, TimeField, SingleOptionSelectorField, MenuSelectorField, NumberField } from '@front/squirtle'

const Row = ({ row, rowId, isSelected, ...props }) => <>
  <TableCell size={'small'} sx={{ maxWidth: 20 }}><TextField name={'schedule.name'} InputProps={{ inputProps: { style: { textAlign: 'center' } } }} /></TableCell>
  <TableCell size={'small'} sx={{ width: 80 }}><TimeField name={'schedule.start_time'} disableOpenPicker /></TableCell>
  <TableCell size={'small'} sx={{ width: 80 }}><TimeField name={'schedule.end_time'} disableOpenPicker /></TableCell>
  <TableCell size={'small'} sx={{ width: 80 }}>
    <NumberField
      name={'priority'}
      type='number'
      minValue={0}
      InputProps={{
        inputProps: { style: { textAlign: 'center' } }
      }}
    />
  </TableCell>
  <TableCell size={'small'} sx={{ width: 50 }}><SingleOptionSelectorField name='immediate_authorized' /></TableCell>
  <TableCell size={'small'} sx={{ width: 50 }}>
    <SingleOptionSelectorField name={'planned_authorized'} /></TableCell>
  <TableCell size={'small'}>
    <MenuSelectorField
      name={'schedule.applicable_days'}
      multiple
      options={[
        { label: I18n.t('day.monday.short'), value: 'monday' },
        { label: I18n.t('day.tuesday.short'), value: 'tuesday' },
        { label: I18n.t('day.wednesday.short'), value: 'wednesday' },
        { label: I18n.t('day.thursday.short'), value: 'thursday' },
        { label: I18n.t('day.friday.short'), value: 'friday' },
        { label: I18n.t('day.saturday.short'), value: 'saturday' },
        { label: I18n.t('day.sunday.short'), value: 'sunday' }
      ]}
    />
  </TableCell>
</>

export default React.memo(Row)
