import Aux from './Aux'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(forcedFormToFilter) {
    return function formToFilter(values, extra, meta, state) {
      const { status: forced_status, ...filterRest } = forcedFormToFilter
      const { requestedAt_start, requestedAt_end, status, companyservice, companycontract, ...rest } = values
      const formatted_filter = {}
      if (_.get(values, 'account_type') === 'b2b') _.merge(formatted_filter, { service: { '!': null } })
      else if (_.get(values, 'account_type') === 'b2c') _.merge(formatted_filter, { service: null })


      if (_.includes(['in_progress'], status)) _.merge(formatted_filter, { status: { '!': ['canceled', 'complete'] } })
      else if (_.includes(['planned'], status)) _.merge(formatted_filter, { schedule_type: 'planned', status: ['created', 'standby'] })
      else _.merge(formatted_filter, { status: _.has(values, 'status') ? status : forced_status })
      const company_filter = !!companyservice || !!companycontract ? !!companyservice ? { service: companyservice } : { service: { contract: companycontract } } : {}
      return {
        ..._.merge(
          {},
          filterRest,
          {
            requestedAt: Aux.formatRequestedAt(requestedAt_start, requestedAt_end),
          },
          formatted_filter,
          company_filter
        ),
        ...rest
      }
    }
  }
}

export default Callbacks