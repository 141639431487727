import moment from 'moment'

const loadStatus = record => {
  if (!record) return 'active'
  else if (!!_.get(record, 'deleted')) return 'archive'
  else if (!!_.get(record, 'active')) return 'active'
  else return 'inactive'
}

const exportComments = (comments, category, types, operator) =>
  !!comments ?
    _.compact([
      _.has(comments, 'driver') && {
        text: _.get(comments, 'driver'),
        category,
        type: _.get(_.find(types, type => type.client_id === 'service_driver'), 'commenttype_id'),
        reporter: operator
      }, _.has(comments, 'operator') && {
        text: _.get(comments, 'operator'),
        category,
        type: _.get(_.find(types, type => type.client_id === 'service_operator'), 'commenttype_id'),
        reporter: operator
      }, _.has(comments, 'information') && {
        text: _.get(comments, 'information'),
        category,
        type: _.get(_.find(types, type => type.client_id === 'service_information'), 'commenttype_id'),
        reporter: operator
      }
    ])
    : undefined

const exportStatus = status => (!!status
  ? {
    active: _.includes(['active'], status),
    deleted: _.includes(['archive'], status)
  }
  : undefined)

const exportDate = (dateValue) =>
  !!dateValue ? moment(dateValue, 'YYYY-MM-DD').toISOString(true) : undefined

const Aux = {
  loadStatus,
  exportComments,
  exportStatus,
  exportDate
}

export default Aux
