import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'
import { GeoUtils, HookUtils } from '@front/volcanion/utils/'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [order, state] = hooks.useModel('order', [record_id], {
    populate: ['assigned_transport', 'source', 'destination'],
    single: true
  })

  const source_id = order?.source?.address_id
  const source_formatted = GeoUtils.format(order?.source)
  const destination_id = order?.destination?.address_id
  const destination_formatted = GeoUtils.format(order?.destination)
  const vehicle_id = order?.assigned_transport?.vehicle


  const mergedProps = {
    source_id,
    source_formatted,
    destination_id,
    destination_formatted,
    vehicle_id
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState(state)}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}
export default withContainer