import React from 'react'

import { Box, Typography } from '@mui/material'
import { MapFactory } from '@front/squirtle'

const Third = ({ source_id, destination_id, vehicle_id, source_formatted, destination_formatted }) =>
  <Box sx={{ border: 1, borderRadius: 2, p: '1px' }}>
    <MapFactory.MapContainer mapStyle={{ height: 300 }} mapId='order_detail_map'>
      <MapFactory.SuperLayer fitBounds>
        <MapFactory.Marker
          config={{
            model_name: 'address',
            ids: [source_id],
            coordinates_path: 'coordinates'
          }}
          styles={{ color: 'green', icon: 'room' }}
        >
          <MapFactory.Popup>
            <Typography fontSize={14}>{source_formatted}</Typography>
          </MapFactory.Popup>
        </MapFactory.Marker>

        <MapFactory.Marker
          config={{
            model_name: 'address',
            ids: [destination_id],
            coordinates_path: 'coordinates'
          }}
          styles={{ color: 'red', icon: 'room' }} x
        >
          <MapFactory.Popup>
            <Typography fontSize={14}>{destination_formatted}</Typography>
          </MapFactory.Popup>
        </MapFactory.Marker>

        <MapFactory.Marker
          config={{
            model_name: 'vehicle',
            ids: [vehicle_id],
            populate: ['current_location'],
            coordinates_path: 'current_location.target'
          }}
          styles={{ color: 'blue', icon: 'local_taxi' }}
        />

      </MapFactory.SuperLayer >
    </MapFactory.MapContainer>
  </Box>

export default React.memo(Third)
