import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, setFormState] = hooks.useFormState('details.source')
  const onSubmitPackage = useCallback(Callbacks.onSubmitPackageHandler(setFormState), [setFormState])

  const mergedProps = {
    onSubmitPackage
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
